import styled from 'styled-components';
import { colors } from "./variables";
export var BaseWrapper = styled.div.withConfig({
  displayName: "spark-thumb__BaseWrapper",
  componentId: "i8mumy-0"
})(["width:100%;height:100%;background:", ";"], function (props) {
  return props.background || colors.primary;
});
export var FlexWrapper = styled(BaseWrapper).withConfig({
  displayName: "spark-thumb__FlexWrapper",
  componentId: "i8mumy-1"
})(["display:flex;align-items:center;justify-content:center;"]);